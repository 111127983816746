@import "../vars.css";

.container {
    max-width: var(--max-width);

    margin-left: auto;
    margin-right: auto;
}

.content {
    min-height: var(--min-height);
}

.errorMessage {
    margin: 20px;
    padding: 20px;
    
    height: 250px;

    font-size: var(--font-size-mediumLarge);
    color: var(--color-text);
}

.header {

    color: var(--color-main);
    border-bottom: solid var(--color-main);
    text-align: left;
    font-size: var(--font-size-large);

    padding: 10px;

}



@media screen and (max-width: 500px) {
    .content {
      min-height: 725px;
    }
}