
@font-face {
  font-family: "Baskerville";
  src: local("LibreBaskerville-Regular"), url("../public/fonts/LibreBaskerville-Regular.ttf") format('truetype');
}


body {
  margin: 0;
  font-family: STIX, Roboto, -apple-system; /* , BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: SanFransico; /* source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

/* Default CSS*/
*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #FAF9F6 !important;
  color: var(--color-text) !important;
}

html {
  scroll-behavior: smooth;
}

p {
  text-indent: 30px;
}

blockquote p {
  margin-left: 75px;
  border-left: 5px solid #ccc;
}

input::file-selector-button {
  background-color: var(--color-grayLight);
  border: none;
  border-radius: var(--border-radius);

  font-size: var(--font-size-tiny);

  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;

  padding: 10px;
  cursor: pointer;
}




