@import "../../vars.css";

.container {
    margin-top: 10px;
    padding: 20px;
    box-shadow: var(--box-shadow);

    background-color: var(--color-grayVeryLight);
}

.headerText {
    margin-bottom: 5px;
    font-size: 25px;
    text-align: left;

    color: var(--color-mainDark);
}

.dnfText {
    margin-bottom: 5px;
    text-align: left;

    color: var(--color-mainLight);

}

.readingDates {
    text-align: left;
}

@media screen and (max-width: 500px) {

    .container {
        width: 325px;
    }

}