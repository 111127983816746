@import "../../vars.css";

.dropdownItem {
    padding: 5px;
    margin: 5px;

    width: 75%;

    cursor: pointer;
    border-radius: var(--border-radius);
    color: var(--color-text);

    text-align: left;
}

.dropdownItem:hover {
    color: var(--color-main);
}