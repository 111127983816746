@import "../../vars.css";

.container {
    display: flex;
    flex-direction: column;

    margin-top: 10px;
    padding: 20px;

    box-shadow: var(--box-shadow);

}

.input{
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: 5px;

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 10px;

    font-family: "SanFransico";
    font-size: var(--font-size-small);
    color: var(--color-text);

}

.inputBody {
    background-color: var(--color-grayLight);
    font-family: "SanFransico";
    font-size: var(--font-size-small);
    color: var(--color-text);

    border-style: none;
    border-radius: 5px;

    padding: 10px;

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;


    max-width: 1500px;

    min-height: 200px;
}

.input:focus {
    outline: none;
}
.inputBody:focus {
    outline: none;
}

.inputGroup {
    display: grid;
    grid-template-columns: 100px 1fr;
}

.inputTitle {
    color: var(--color-text);

    margin-top: 20px;
    margin-left: 15px;

    justify-self: start;
}

.pageAndReading {
    display: grid;
    flex-direction: column;

    align-items: start;
}


.saveAndCancel {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.submitButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;

    cursor: pointer;

    justify-self: end;

    color: var(--color-text);
    font-family: "SanFransico";
}

.cancelButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;
    
    cursor: pointer;

    color: var(--color-text);
    font-family: "SanFransico";
}

.submitButton:hover {
    background-color: var(--color-gray);
}

.cancelButton:hover {
    background-color: var(--color-gray);
}

@media screen and (max-width: 500px) {

    .input {
        font-size: var(--font-size-small);
    }

    .inputBody {
        font-size: var(--font-size-small);
    }

    .inputGroup {
        grid-template-columns: 1fr;
    }

    .submitButton {
        margin-right: 0;
    }

}