@import "../../vars.css";

.container {
    display: flex;
    padding: 5px;
}

.button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.r_active {
    content: url("/public/assets/images/rightFilledStar.svg");

    height: 32px;
}

.l_active {
    content: url("/public/assets/images/leftFilledStar.svg");

    height: 32px;
}

.r_inactive {
    content: url("/public/assets/images/rightEmptyStar.svg");

    height: 32px;
}

.l_inactive {
    content: url("/public/assets/images/leftEmptyStar.svg");

    height: 32px;
}
