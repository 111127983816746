@import "../../vars.css";

.container {
    box-shadow: var(--box-shadow);
    padding: 10px;
    cursor: pointer;
}

.content {
    text-align: left;
    color: var(--color-mainDark);
    font-weight: 500;
    
}

.firstWordsOfTitle {
    color: var(--color-main);

    font-family: "SanFransico";
    text-align: left;
    font-size: var(--font-size-small);
}

.lastWordOfTitle {
    color: var(--color-main);
    white-space: nowrap;

    font-family: "SanFransico";
    text-align: left;
    font-weight: 300;
    font-size: var(--font-size-small);
}

.starIconFilled {
    content: url("/public/assets/images/fullFilledStar.svg");
    height: 15px;
    margin-left: 5px;
}

.starIconEmpty {
    content: url("/public/assets/images/fullEmptyStar.svg");
    height: 15px;
    margin-left: 5px;

}

.starIconEmpty:hover {
    background-color: var(--color-grayVeryLight);
    border-radius: 5px;
}