@import "../../vars.css";

.container {
    margin-top: 50px;
    border-top: solid var(--color-main);

    width: 100%;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: var(--grid-gap-small);

    margin-top: 10px;
}

@media screen and (max-width: 500px) {

    .container {
        margin-top: 30px;
        width: auto;
    }
    
    .content {
        width: 325px;
        grid-template-columns: 1fr 1fr;
    }
}