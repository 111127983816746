@import "../../../vars.css";

.compactBook {
    display: flex;
    flex-direction: row;
    align-items: start;

    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;

    padding: 15px;

    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.mobileContainer {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;

    padding: 15px;

    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.compactBook:first-child {
    margin-top: 15px;
}

.compactBook:last-child {
    margin-bottom: 20px;
}

.mobileContent {
    display: flex;
    flex-direction: column;
}

.link {
    text-decoration: none;
}

.mobileBookInfo {
    display: flex;
    flex-direction: row;
}

/* Book Information */

.bookTitle {
    text-align: left;
    text-decoration: none;

    margin-top: 10px;
}

.firstWordsOfTitle {
    color: var(--color-main);

    font-family: "SanFransico";
    font-weight: 600;
    text-align: left;
    font-size: var(--font-size-mediumSmall);
}

.lastWordOfTitle {
    color: var(--color-main);
    white-space: nowrap;

    font-family: "SanFransico";
    text-align: left;
    font-weight: 600;
    font-size: var(--font-size-mediumSmall);
}

.starIconFilled {
    content: url("/public/assets/images/fullFilledStar.svg");
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
}

.bookSeries {
    text-align: left;
    font-size: var(--font-size-tiny);

    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 7px;

    color: var(--color-mainLight);
}

.infoAndTags{
    display: flex;
    flex-direction: column;
    
    vertical-align: center;
}

.mobileInfo {
    display: flex;
    flex-direction: column;
    
    vertical-align: center;
}

.titleAndSeriesAndAuthor {
    display: flex;
    flex-direction: column;

    margin-right: 25px;
    margin-left: 7px;
}

.tags {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;

    align-self: baseline;

    display: flex;
}

.bookReview {
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;

    font-size: var(--font-size-small);
    text-align: left;
    text-indent: 15px;
    line-height: 145%;

    color: var(--color-text);

}

.readMoreOrLessButton {
    margin-bottom: 10px;
    padding: 5px;

    background-color: transparent;

    border: none;
    border-radius: 15px;

}

.readMoreOrLessButton:hover {
    background-color: var(--color-grayLight);
}

.carrot {
    height: 20px;
}

.spacer {
    height: 12px;
}

@media screen and (max-width: 500px) {

    .bookTitle {
        font-size: 20px;
    }

    .bookSeries {
        font-size: 18px;
    }

    .tags {
        display: grid;
        grid-template-columns: 145px 145px;

        margin-left: 5px;
    }

}

