@import "../../vars.css";

.container {
    display: flex;
    flex-direction: column;

    justify-content: center;

    max-width: var(--max-width);

    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

}

.options {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}

.noOptions {
    color: var(--color-text);

    margin-top: 50px;
    padding: 20px;

    grid-column-start: 2;
}

.loadingGif {
    grid-column-start: 2;

    margin-top: 50px;
    justify-content: center;
}


.header {
    display: flex;
    flex-direction: row;

    border-bottom: solid var(--color-main);
    color: var(--color-main);
    text-align: left;
    font-size: var(--font-size-mediumSmall);

    margin-left: 5%;
    margin-right: 5%;

}

.inputFeilds {
    display: flex;
    flex-direction: row;

    justify-content: center;

    margin-top: 50px;
    margin-bottom: 50px;
}

.label {
    margin-top: 10px;
    color: var(--color-text);
}

.labelCheck {
    margin-top: 10px;
    margin-left: 10px;

    color: var(--color-text);
    font-weight: 600;

}

.cover {
    margin-left: 25px;
}

.textFields{
    margin-left: 25px;
}

.input {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 10px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);

    width: 400px;
}

.input:focus {
    outline: none;
}

.shortInput {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 10px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);

    width: 355px;
}

.shortInput:focus {
    outline: none;
}

.genreContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    margin-left: 5%;
    margin-right: 5%;

    margin-top: 10px;
    margin-bottom: 20px;
}

.activeGenre {

    border: none;
    border-radius: var(--border-radius);
    background-color: var(--color-mainLight);

    color: var(--color-mainDark);

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;

}

.inactiveGenre {

    border: none;
    border-radius: var(--border-radius);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;
}

.inactiveGenre:hover {
    background-color: var(--color-gray);
}

.authorAndAdd {
    display: flex;
    justify-content: left;
    align-items: last baseline;
}

.addButton {
    width: 30px;
    height: 38px;

    font-size: var(--font-size-tiny);
    color: var(--color-text);

    border: none;
    border-radius: var(--border-radius);
}

.suggestions {
    display: flex;
    flex-direction: column;

    align-items: center;

    margin-left: 50px;
    padding: 10px;

    box-shadow: var(--box-shadow);
    border: none;
    border-radius: var(--border-radius);

    width: 400px;
    min-height: 600px;
}

.suggestion {
    cursor: pointer;
}

.selection {
    display: flex;
    flex-direction: row;

    justify-content: left;
    align-items: center;
}

.duplicateSeriesNum {
    color: var(--color-warning);
    margin-top: 3px;
}

.fileSelector {
    margin-left: 15px;
    margin-right: 15px;

    margin-bottom: 25px;
    color: var(--color-text);
}

.submitButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);

    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 215px;

    cursor: pointer;
}

.submitButton:hover{
    background-color: var(--color-gray);
}

.badSubmitButton {
    background-color: var(--color-warning);
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);

    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 200px;

    cursor: pointer; 
}

.badSubmitButton:hover {
    background-color: var(--color-gray);
}


.removeSelection {
    content: url("/public/assets/images/deleteIcon.svg");
    height: 20px;
    margin-left: 10px;
    cursor: pointer;

    margin-right: 45px;
}

.removeSelection:hover {
    content: url("/public/assets/images/deleteIconHover.svg");
    height: 20px;
    margin-left: 10px;
    cursor: pointer;

    margin-right: 45px;
}