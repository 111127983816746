@import "../../vars.css";

.container {
    margin-top: 20px;
}

.dateInputStart {
    margin-right: 5px;

    border: none;
    background-color: var(--color-grayLight);

    color: var(--color-text);
}

.dateInputFinish {
    margin-left: 5px;

    border: none;
    background-color: var(--color-grayLight);
    
    color: var(--color-text);
}

.deleteButton {

    background: transparent;
    border: none;

    margin-left: 5px;

}

.deleteIcon {
    content: url("/public/assets/images/deleteIcon.svg");
    height: 15px;
}

.deleteIcon:hover {
    content: url("/public/assets/images/deleteIconHover.svg");
}