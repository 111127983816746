@import "../../vars.css";

.container {
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;

    max-width: var(--max-width);
}

.contentEXP {
    margin-left: 5%;
    margin-right: 5%;

    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 10px;
}

.contentCOVER {
    margin-left: 5%;
    margin-right: 5%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    gap: 10px;
}


.header { 
    display: grid;
    grid-template-columns: 2fr 1fr;
    
    margin-top: 50px;

    margin-left: 5%;
    margin-right: 5%;
 
    padding: 5px;
    padding-bottom: 10px;

    font-weight: 900;

    border-bottom: solid var(--color-main);
    color: var(--color-main);

    font-size: var(--font-size-large);
    text-align: left;
    font-weight: 550;
}

.pageSelector {
    align-self: last baseline;
    justify-self: right;
}

 .carrot {
    height: 20px;
}

.carrotNextButton {
    background-color: transparent;
    
    border: none;
    border-radius: 15px;

    padding: 5px;

    width: 35px;
}

.carrotBackButton {
    background-color: transparent;
    
    border: none;
    border-radius: 15px;

    padding: 5px;

    width: 35px;
}
.carrotBackButton:hover {
    background-color: var(--color-grayLight);
}
.carrotNextButton:hover {
    background-color: var(--color-grayLight);
}

.bottomPageSelector {
    display: flex;

    margin-right: 5%;
    margin-left: 5%;
    
    margin-top: 20px;

    justify-content: space-between;

}

.genreContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: var(--grid-gap-small);

    margin-left: 5%;
    margin-right: 5%;

    margin-bottom: 20px;
}

.typeContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: var(--grid-gap-small);

    margin-left: 5%;
    margin-right: 5%;

    margin-bottom: 20px;
}

.activeGenreOrType {

    border: none;
    border-radius: var(--border-radius);
    background-color: var(--color-mainLight);

    color: var(--color-mainDark);

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;

}

.inactiveGenreOrType {

    border: none;
    border-radius: var(--border-radius);
    background-color: var(--color-grayLight);

    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;

    text-align: center;

    cursor: pointer;
}

.inactiveGenreOrType:hover {
    background-color: var(--color-gray);
}


.emptyShelf {
    margin-top: 20px;

    color: var(--color-text);
    font-size: var(--font-size-mediumLarge);
    font-weight: 600;

    grid-column: 1 / span all;
}

.loader {
    margin-top: 20px;
    height: var(--min-height);

    justify-self: center;
    grid-column: 1 / span all;
}

.sizeSelector {
    margin-left: 5%;
    margin-bottom: 10px;

    margin-top: 10px;
}

@media screen and (max-width: 500px) {

    .contentCOVER {
       grid-template-columns: 1fr 1fr;
    }

    .contentEXP {
        grid-template-columns: 1fr;
    }

    .genreContainer {
        grid-template-columns: 1fr 1fr 1fr;
    }

}
