@import "../../vars.css";

.container {
    position: absolute;

    background-color: var(--color-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    left: auto;
    padding: 20px;
}

.content {
    color: var(--color-text);
    font-family: "SanFransico";
    text-align: left;
    
    columns: 2;
}

.header {
    color: var(--color-main);
    margin-bottom: 10px;
}