@import "../../vars.css";

.reviewContainer {
    background-color: var(--color-mediumlight);
    box-shadow: var(--box-shadow);

    margin-right: 5%;
    margin-left: 15px;

    margin-top: 30px;

    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;

    border-top: solid var(--color-main);
    width: 100vh;
}

.titleAndButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;

    padding-bottom: 5px;

    color: var(--color-main);
}

.infoTitle {
    padding: 10px;
    font-size: var(--font-size-mediumLarge);
    text-align: left;
    font-weight: 550;
}

.headerbuttons {
    display: flex;
    flex-direction: row;

    justify-self: end;
}

.editButton {
    border: none;
    background-color: transparent;

    margin-top: 5px;

    padding-right: 5px;

}

.editButtonImage {
    height: 20px;
    margin-right: 10px;
    align-self: baseline;

    cursor: pointer;
}

.markdownInfo {
    display: flex;
    justify-content: flex-end;
}

.review {
    text-align: left;
    text-indent: 15px;

    line-height: 145%;

    color: var(--color-text);
}

.spacer {
    height: 12px;
}

.editingReviewArea {
    display: flex;
    flex-direction: column;
    justify-content: right;

    margin-right: 10px;
    margin-left: 10px;

}

.reviewTextBox{
    background-color: var(--color-grayLight);

    border-style: none;
    border-radius: 15px;
    
    padding: 10px;

    margin-top: 5px;
    margin-bottom: 10px;

    width: 100%;
    line-height: 145%;

}

.reviewTextBox:focus {
    outline: none;
}

.footerButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.submitButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    margin-top: 10px;

    padding: 10px;

    width: 100px;

    cursor: pointer;

    justify-self: end;
}

.cancelButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    margin-top: 10px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;

    cursor: pointer;
}

.submitButton:hover {
    background-color: var(--color-gray);
}

.cancelButton:hover {
    background-color: var(--color-gray);
}

.reviewPeiece {
    line-height: 145%;
}

@media screen and (max-width: 500px) {

    .reviewContainer {
        width: 325px;
        margin-left: 5%;
        margin-right: 5%;
    }

    .reviewTextBox {
        font-size: var(--font-size-small);
    }
}



