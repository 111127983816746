@import '../../vars.css';

.container {
    display: flex;
    align-items: center;
    justify-content: left;

    border: none;
    border-radius: var(--border-radius);
    background-color: var(--color-grayLight);

    padding: 5px;
    margin: 5px;

    break-inside: avoid;

}

.containerSelected {
    display: flex;
    align-items: center;
    justify-content: left;

    border: none;
    border-radius: var(--border-radius);
    background-color: var(--color-mainLight);

    margin: 5px;
    padding: 5px;

    break-inside: avoid;
}


.gridContainer {
    display: grid;
    grid-template-columns: 1fr 20px;

    width: 100%;
}

.link {
    text-decoration: none;
}

.tagValue {
    width: 100%;

    background-color: transparent;
    color: var(--color-text);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;

    text-align: center;

    cursor: pointer;

}

.tagValueTVShow {
    width: 100%;

    background-color: transparent;
    color: var(--color-tvshow-dark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;

    text-align: center;

    cursor: pointer;
}

.tagValueSelected {

    width: 100%;

    background-color: transparent;
    color: var(--color-mainDark);
    border: none;

    font-size: var(--font-size-tiny);
    font-weight: 550;
    font-family: "SanFransico";

    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;

    text-align: center;

    cursor: pointer;

}

.container:hover {
    background-color: var(--color-gray);
} 

.deleteButton {
    background-color: transparent;
    border: none;

    margin-right: 5px;

    justify-self: end;
}


.deleteButtonIcon {
    content: url("/public/assets/images/deleteIcon.svg");

    height: 14px;

    background-color: transparent;
    border: none;

    cursor: pointer;
}

.deleteButtonIcon:hover {
    content: url("/public/assets/images/deleteIconHover.svg");
}

.deleteButtonIconHide {
    height: 0px;
}

@media screen and (max-width: 500px) {

    .tagValue, .tagValueSelected {
        word-break: break-word;

        hyphens: auto;
    }

}