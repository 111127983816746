@import "../../../vars.css";

.container {


}

.label {
    margin-top: 10px;
    color: var(--color-text);
}

.input {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 10px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);

    width: 400px;
}

.input:focus {
    outline: none;
}