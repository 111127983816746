
:root {
    /*colors*/
    
/*     --color-text: #5F5163;
    --color-textLight: #a698aa;
    --color-light: #F0ECE3;
    --color-mediumlight: #e2d7c9;
    --color-mediumdark: #C7B198;
    --color-dark: #A68DAD; 
    --color-tagbg: #ece5db; 
    
    
    
    #fffafa*/

    --color-bg: #fffafa;
    --color-text: #28344E;

    /* OLD TEAL COLOR */
    /* --color-text: #073642; */
    /* --color-main: #58989D;  */
    /* --color-mainDark: #2D4C4E;  */
    /* --color-mainLight: #89B9BD; */

    /* --color-OTHERMEDIATYPE-main: #AE7184;
    --color-tvshow-main: #84AE71;
    --color-tvshow-light: #B1CBA4;
    --color-tvshow-dark: #556F48; */
    
    --color-main: #7184AE;
    --color-mainDark: #324162;
    --color-mainLight: #A9C1F5;

    /* --color-grayVeryLight: #F2F2F2; */
    /* --color-grayLight: #ececec; */
    
    --color-grayVeryLight: #F3F4F8;
    --color-grayLight: #ECEEF4;
    --color-gray: #d9d9d9;
    --color-grayDark: #32292F;

    --color-warning: rgba(251, 0, 0, 0.65);
    --color-warningLight:  rgba(251, 0, 0, 0.55);

    --font-size-tiny: 14px;
    --font-size-small: 16px;
    --font-size-mediumSmall: 24px;
    --font-size-mediumLarge: 32px;
    --font-size-large:48px;

    --grid-gap: 15px;
    --grid-gap-small: 10px;
    --border-radius: 5px;
    --speed: 100ms;
    --max-width: 1500px;
    --min-height: 1000px;
    --box-shadow: rgba(149, 157,165, 0.2) 0px 0px 24px;
}