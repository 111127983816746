@import "../../vars.css";

.contentOpen {
    position: absolute;
    display: flex;
    flex-direction: column;

    align-items: left;

    margin-top: 7px;

    
    padding: 5px;
    
    font-size: 28px;

    background-color: var(--color-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    
    max-height: 40vh;
    width: 350px;
}

.contentClosed {
    opacity: 0;
    
}


@media screen and (max-width: 500px) {

    .contentOpen {
        width: 310px;
    }

}