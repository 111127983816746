@import "../../../vars.css";

.container {
    display: flex;
    flex-direction: row;
    
    padding: 18px;

    margin-bottom: 35px;
    margin-right: 15px;

    box-shadow: var(--box-shadow);
}

.content {
    display: flex;
    align-items: start;
}

.link {
    text-decoration: none;
}

.bookInfo {
    margin-top: 7px;
    margin-left: 5px;

    text-align: left;
}

.bookCover {
    height: 200px;
    width: auto;
    align-items: center;

    margin: 10px;

    border-radius: 5px;
}

.seriesNum {
    font-size: var(--font-size-mediumSmall);
    text-align: left;
    font-family: "SanFransico";
    font-weight: 600;

    margin-bottom: 3px;

    text-decoration: none;
    color: var(--color-main)

}
.firstWordsOfTitle {
    color: var(--color-main);

    font-family: "SanFransico";
    font-weight: 600;
    text-align: left;
    font-size: var(--font-size-mediumSmall);
}

.lastWordOfTitle {
    color: var(--color-main);
    white-space: nowrap;

    font-family: "SanFransico";
    text-align: left;
    font-weight: 600;
    font-size: var(--font-size-mediumSmall);
}
.starIconFilled {
    content: url("/public/assets/images/fullFilledStar.svg");
    height: 20px;
    margin-left: 15px;
    cursor: pointer;
}

.bookSeries {
    text-align: left;

    margin-bottom: 3px;

    color: var(--color-mainLight)
}

.bookAuthor {
    font-size: 20px;
    margin-left: 5px;
    text-align: left;

    margin-bottom: 3px;

    color: var(--color-mainDark)
}


.description {
    display: flex;
    flex-direction: column;

    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;

    font-size: var(--font-size-small);
    text-align: left;
    text-indent: 15px;
    line-height: 145%;

    color: var(--color-text);

}

.readMoreOrLessButton {
    background-color: transparent;

    border: none;
    border-radius: 15px;

    padding: 5px;

    margin-left: 40%
}

.readMoreOrLessButton:hover {
    background-color: var(--color-grayLight);
}

.carrot {
    height: 20px;
}

.spacer {
    height: 12px;
}

