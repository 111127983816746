@import "../../vars.css";

.container {
    cursor: pointer;
}

.profileIcon {
    height: 30px;

}

@media screen and (max-width: 500px) {

    .profileIcon {
        height: 25px;
        margin-top: 2px;
    }
}