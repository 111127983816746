@import '../../../vars.css';

.container {
    display: flex;
    flex-direction: row;

    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    padding: 15px;

    text-decoration: none;

    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);

}

.content {
    margin-left: 5px;
}

.progressContainer {
    display: grid;
    grid-template-columns: 250px 25px;
    column-gap: 5px;

    border-radius: 15px;
    
    align-items: flex-start;
}

.progressBar {
    margin-top: 5px;
}

.editButton {
    border: none;
    background-color: transparent;
    margin-top: 5px;
    cursor: pointer;

    height: 20px;
    width: 20px;
}

.editButtonImage {
    z-index: 1;
    height: 20px;
    width: 20px;
    align-self: baseline;

    cursor: pointer;
}

.link {
    text-decoration: none;
}

/* Book Information */

.bookTitle {
    margin-top: 4px;

    font-size: var(--font-size-mediumLarge);
    color: var(--color-main);
    text-align: left;
}

.bookSeries {
    text-align: left;

    color: var(--color-mainLight);
}


/* Edit Box */

.editContainer {
    display: flex;
    flex-direction: column;
    
    border: none;
    box-shadow: var(--box-shadow);

    border-top: solid var(--color-main);

    margin-top: 10px;
    padding: 15px;
}

.numberAndSave {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    margin-bottom: 5px;
}

.inputBox {
    width: 105px;
    padding: 5px;

    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);
    color: var(--color-text);
    font-family: "SanFransico";
}

.inputBox:focus {
    outline: none;
}

.saveButton {
    border-radius: var(--border-radius);

    background-color: var(--color-grayLight);
    color: var(--color-text);

    text-align: center;
    font-size: var(--font-size-tiny);

    width: 105px;

    border: none;

    padding: 10px;
    
}

.saveButton:hover {
    background-color: var(--color-gray);
}

.markButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.markButton {
    border: none;
    background-color: var(--color-grayLight);
    border-radius: 5px;
    border-width: 2px;

    font-size: var(--font-size-tiny);
    color: var(--color-text);

    margin-top: 5px;

    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;

    text-align: center;

    height: 33px;
}

.markButton:hover {
    background-color: var(--color-gray);
}


@media screen and (max-width: 830px) {
    .content {
        align-items: center;
    }

    .progressBar {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 500px) {
    .container {
        width: 325px;
        margin-left: 0;
    }

    .progressContainer {
        grid-template-columns: 150px 25px;
    }

    .bookTitle {
        font-size: var(--font-size-mediumSmall);
    }
    .bookSeries {
        font-size: var(--font-size-small)
    }

    .inputBox {
        font-size: var(--font-size-small);
        width: 65px;
    }

    .saveButton {
        font-size: 12px;
        width: 45px;
    }

    .markButton {
        font-size: 10px;
        width: 55px;
    }
}