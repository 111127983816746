@import "../../vars.css";

.container {
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;

    max-width: var(--max-width);
}

.content {
    margin-left: 5%;
    margin-right: 5%;
}

.seriesName{
    color: var(--color-main);
    border-bottom: solid var(--color-main);
    text-align: left;
    font-size: var(--font-size-large);
    font-weight: 550;

    padding: 10px;
}

.workContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 930px) {
    .workContainer {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 500px) {
    .seriesName {
        font-size: var(--font-size-mediumLarge);
    }
}