@import "../../../vars.css";

.container {
    display: flex;
    flex-direction: row;

    align-items: start;

    margin-top: 10px;
    padding: 18px;

    border-radius: 5px;

    text-decoration: none;

    box-shadow: var(--box-shadow);

    height: 100%;

}

.link {
    text-decoration: none;
}

.bookTitle {
    margin-top: 4px;
    margin-left: 5px;
    
    font-size: var(--font-size-mediumSmall);
    color: var(--color-main);
    text-align: left;
    text-decoration: none;
}

.author {
    margin-left: 5px;
}

.bookSeries {
    text-align: left;
    
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 7px;
    margin-right: 7px;

    color: var(--color-mainLight);
}

.bookDescription {
    display: flex;
    flex-direction: column;

    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 5px;

    text-align: left;
    text-indent: 15px;
    line-height: 145%;

    color: var(--color-text);
}

.spacer {
    height: 12px;
}

.readMoreOrLessButton {
    background-color: transparent;
    color: var(--color-main);

    border: none;
    border-radius: 15px;

    padding: 5px;

}

.readMoreOrLessButton:hover {
    background-color: var(--color-grayLight);
}

.carrot {
    height: 20px;
}
