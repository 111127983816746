@import "../../vars.css";

.container {
    margin-left: auto;
    margin-right: auto;

    max-width: var(--max-width);
}

.content {
    display: grid;
    grid-template-columns: 50px 1fr 50px;

    margin-left: 5%;
    margin-right: 5%;

    margin-top: 20px;

    align-items: center;
    justify-content: center;

}

.navItems {
    display: flex;
    flex-direction: row;
}

.linksAndSearch {
    margin-top: 20px;

    display: flex;
    flex-direction: column;

    align-items: center;
    
}

.searchBar {
    margin-top: 10px;
    margin-bottom: 30px;

    justify-self: start;
}

.profile {
    justify-content: end;
    align-items: baseline;

    height: 100px;
}

.profileIcon {
    height: 25px;
    margin-right: 15px;
}

.logoContainer {
    justify-content: start;
    align-items: baseline;

    height: 150px;
}


.logo {
    width: auto;
    height: 75px;

    margin-top: 15px;
    margin-right: 20px;

    vertical-align: middle;
}

.activeNavbarLink {
    opacity: 1;

    margin-right: 40px;

    color: var(--color-main);

    font-size: var(--font-size-large);
    text-decoration: none;
    
    vertical-align: bottom;

}
  
.navbarLink {
    opacity: 0.55;

    margin-right: 40px;

    color: var(--color-mainLight);
    font-size: var(--font-size-large);
    text-decoration: none;
    
    vertical-align: bottom;

}

.activeNavbarLink:last-child {
    margin-right: 0;
}

.navbarLink:last-child {
    margin-right: 0;
}

.mobileTopRightIcons {
    display: flex;
    flex-direction: row;

    justify-self: end;
    align-items: center;
}

.hamburger {
    height: 30px;
    cursor: pointer;
}

.searchIcon {
    height: 25px;

    margin-right: 5px;
    cursor: pointer;
}

.overlay {
    position: fixed;
    display: block;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: var(--color-bg);

    z-index: 6;
}

.hambergerOverlayContent {
    margin-right: 5%;
    text-align: right;
}

.mobileNavLinks {
    display: flex;
    flex-direction: column;

    cursor: pointer;
}

.mobileProfileLinks {
    display: flex;
    flex-direction: column;

    margin-right: 5%;
    
    cursor: pointer;

    font-size: var(--font-size-mediumLarge);
    text-align: right;

}

.profileLink {
    color: var(--color-main);
    text-decoration: none;

    margin-bottom: 10px;
}

.signOut {
    color: var(--color-warningLight);
    
}

@media screen and (max-width: 975px) {
    .navbarLink {
        font-size: var(--font-size-mediumLarge);
    }

    .activeNavbarLink {
        font-size: var(--font-size-mediumLarge);
    }
}   


@media screen and (max-width: 500px) {

    .container {
        width: 100%;
    }

    .content {
        grid-template-columns: 1fr 1fr;
    }

    .navbarLink {
        font-size: var(--font-size-mediumLarge);

        margin-right: 0;
        margin-bottom: 10px;
    }

    .activeNavbarLink {
        font-size: var(--font-size-mediumLarge);
        margin-right: 0;
        margin-bottom: 10px;
    }

    .logo {
        height: 50px;
        margin-right: 15px;
    }

    .logoContainer {
        height: 75px;
    }

    .navItems {
        flex-direction: column;
    }

    .searchBar {
        width: 100%;
    }

    .searchIcon {
        padding-right: 15px;
    }

    .profile {
        align-items: center;
        height: 100%;

        padding-right: 15px;
    }
    


}