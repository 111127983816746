@import "../../vars.css";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;

    background-color: var(--color-bg);
    
    border-radius: var(--border-radius);

    font-family: "SanFransico";
}

.icon {
    height: 20px;
    margin-right: 5px;
}

.input {
    border: none;
    background-color: transparent;
    height: 100%;
    font-size: 20px;
}

.input:focus{
    outline: none;
}

@media screen and (max-width: 500px) {

    .input {
        font-size: var(--font-size-small);
        width: 175px;
    }
}