@import "../../../vars.css";

.container {
    max-width: var(--max-width);

    margin-bottom: 25px;

}

.header {
    display: flex;
    flex-direction: row;

    border-bottom: solid var(--color-main);
    color: var(--color-main);
    text-align: left;
    font-size: var(--font-size-mediumSmall);

    margin-left: 5%;
    margin-right: 5%;

}

.headerTitle {
    padding-right: 15px;
    margin-top: 10px;
}

.options {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}

.noOptions {
    color: var(--color-text);

    margin-top: 50px;
    padding: 20px;

    grid-column-start: 2;
}

.loader {
    grid-column-start: 2;

    margin-top: 50px;
    justify-content: center;
}