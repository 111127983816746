@import "../../vars.css";

.titleAndButton {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}

.infoTitle {
    padding: 10px;
    font-size: 25px;
    text-align: left;

    color: var(--color-mainDark);
}


.readings {
    display: flex;
    flex-direction: column;

    text-align: left;
    margin-left: 5%;
}

.editButton {
    border: none;
    background-color: var(--color-medlight);
    margin-top: 5px;

    cursor: pointer;
}

.editButtonImage {
    height: 19px;
    align-self: baseline;
}

.buttons {
    display: flex;
    flex-direction: row;

}

.saveButton {
    border-radius: var(--border-radius);
    border: none;

    background-color: var(--color-grayLight);
    
    color: var(--color-text);
    text-align: center;
    font-size: var(--font-size-tiny);

    width: 65px;
    height: 40px;

    margin-top: 10px;
    margin-left: 96.167px;

    cursor: pointer;
    align-self: center;
    
}

.saveButton:hover {
    background-color: var(--color-gray);
}

/* .addReadingButton {
    border-radius: var(--border-radius);
    border: none;

    background-color: var(--color-grayLight);
    
    color: var(--color-text);
    text-align: center;
    font-size: var(--font-size-tiny);

    width: 65px;
    height: 40px;

    margin-top: 10px;
    margin-right: 20px;
    margin-left: 53.667px;

    cursor: pointer;
    align-self: center;
} */

/* .addReadingButton:hover {
    background-color: var(--color-gray);
} */

.message {
    color: var(--color-text);
    margin-top: 10px;
}