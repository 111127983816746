@import "../../vars.css";

.container {
    position: absolute;
}

.contentOpen {
    z-index: 3;
    position: absolute;
    
    display: flex;
    flex-direction: column;

    margin-top: 7px;
    
    right: -2em;
    left: auto;

    padding: 5px;
    
    font-size: 18px;

    background-color: var(--color-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    
    max-height: 40vh;
    width: 150px;
}

.link {
    text-decoration: none;
}