@import "../../vars.css";

.dropdownButtonOpen {
    display: flex;
    align-items: center;


    min-width: 125px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;

    background-color: var(--color-bg);

    cursor: pointer;

    border-radius: var(--border-radius);
    border-style: none;

    font-size: var(--font-size-mediumSmall);
    font-weight: 600;
    font-family: "SanFransico";
    color: var(--color-main);
    text-align: left;
}

.dropdownButtonClosed {
    display: flex;
    align-items: center;


    min-width: 125px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;

    background-color:var(--color-bg);


    cursor: pointer;

    border-radius: var(--border-radius);
    border-style: none;

    font-size: var(--font-size-mediumSmall);
    font-weight: 600;
    font-family: "SanFransico";
    color: var(--color-main);
    text-align: left;
}

.carrot {
    height: 24px;

    display: flex;
    align-items:center;

    margin-left: 15px;

}

@media screen and (max-width: 500px) {

    .dropdownButtonClosed {
        font-size: var(--font-size-small);
        min-width: 100px;
    }
    .dropdownButtonOpen {
        font-size: var(--font-size-small);
        min-width: 100px;
    }

}

