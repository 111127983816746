@import "../../vars.css";

.container {
    position: relative;
    width: 500px;
}


@media screen and (max-width: 500px) {
    .container {
        width: 250px;
    }
}