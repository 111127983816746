@import "../../vars.css";

.container {
    margin-top: 10px;

    padding: 20px;

    box-shadow: var(--box-shadow);
}

.titleContainer {
    display: grid;
    grid-template-columns: 5fr 1fr;

}

.title {
    margin-bottom: 5px;

    text-align: left;
    color: var(--color-main);
    font-size: var(--font-size-mediumSmall);
}

.reading {
    margin-bottom: 5px;

    text-align: left;
    color: var(--color-mainDark);
}

.time {
    margin-bottom: 5px;

    text-align: left;
    color: var(--color-mainDark);
}

.page {
    margin-bottom: 20px;

    text-align: left;
    color: var(--color-mainDark);
}

.body {
    margin-bottom: 5px;
    text-align: left;
    color: var(--color-text);
    text-indent: 15px;
    line-height: 145%;
}

.spacer {
    height: 12px;
}

.editButton {
    background-color: transparent;
    border: none;

    justify-self: end;
    margin-right: 15px;

    cursor: pointer;
}

.editButtonImage {
    height: 20px;
}

.editingContainer {
    display: flex;
    flex-direction: column;

}

.input{
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 10px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);
    color: var(--color-text);
}

.inputBody {
    background-color: var(--color-grayLight);
    font-family: "SanFransico";
    font-size: var(--font-size-tiny);
    color: var(--color-text);

    border-style: none;
    border-radius: 5px;

    padding: 10px;

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    max-width: 1500px;

    height: 200px;
}

.input:focus {
    outline: none;
}
.inputBody:focus {
    outline: none;
}

.inputGroup {
    display: grid;
    grid-template-columns: 100px 1fr;
}

.inputTitle {
    color: var(--color-text);

    margin-top: 20px;
    margin-left: 15px;

    justify-self: start;
}

.pageAndReading {
    display: grid;
    flex-direction: column;

    align-items: start;
}


.buttonBar {
    display: grid; 
    grid-template-columns: 1fr 1fr;
}

.cancelAndDelete {
    display: flex;
    flex-direction: row;
}

.submitButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);
    color: var(--color-text);
    font-family: "SanFransico";

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;

    cursor: pointer;

    justify-self: end;
}

.submitButton:hover{
    background-color: var(--color-gray);
}

.cancelButton {
    background-color: var(--color-grayLight) ;
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);
    color: var(--color-text);
    font-family: "SanFransico";

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;

    cursor: pointer;
    
}

.cancelButton:hover{
    background-color: var(--color-gray);
}

.deleteButton {
    background-color: var(--color-grayLight) ;
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);
    color: var(--color-warning);
    font-family: "SanFransico";

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;

    cursor: pointer;

}

.deleteButton:hover {
    background-color: var(--color-warningLight);
    color: var(--color-grayLight);
}

@media screen and (max-width: 500px) {

    .input {
        font-size: var(--font-size-small);
    }

    .inputBody {
        font-size: var(--font-size-small);
    }

    .container {
        width: 325px;
    }


    .inputGroup {
        grid-template-columns: 1fr;
    }

    .buttonBar {
        grid-template-columns: 1fr;
    }

    .cancelAndDelete {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .deleteButton {
        justify-self: end;
        margin-right: 0;
    }

    .submitButton {
        width: 267.667px;
        margin-right: 0;
    }
}