@import "../../vars.css";

.container {
    display: flex;
    flex-direction: column;

    margin-top: 10px;
    padding: 20px;

    box-shadow: var(--box-shadow);

}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.bookB {
    display: flex;
    flex-direction: column;

    margin-left: 15px;
}

.input {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: 5px;

    max-width: 350px;

    padding: 10px;

    margin-right: 15px;
    margin-top: 10px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);
    color: var(--color-text);
}

.input:focus {
    outline: none;
}

.recipricalContainer {
    display: flex;
    flex-direction: row;

    align-items: start;


    margin-top: 20px;

}

.label {
    color: var(--color-text);
    margin-left: 10px;
}


.suggestions {
    display: flex;
    flex-direction: column;

    margin-top: 10px;

    align-items: start;
}

.suggestion {
    justify-self: center;
}

.selectedBook {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.submitButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;

    cursor: pointer;

    justify-self: end;

    color: var(--color-text);
    font-family: "SanFransico";
}

.cancelButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding: 10px;

    width: 100px;
    
    cursor: pointer;

    color: var(--color-text);
    font-family: "SanFransico";
}

.submitButton:hover {
    background-color: var(--color-gray);
}

.cancelButton:hover {
    background-color: var(--color-gray);
}

.removeSelection {
    content: url("/public/assets/images/deleteIcon.svg");
    height: 20px;
    margin-left: 10px;
    cursor: pointer;

    margin-right: 45px;
}

.removeSelection:hover {
    content: url("/public/assets/images/deleteIconHover.svg");
    height: 20px;
    margin-left: 10px;
    cursor: pointer;

    margin-right: 45px;
}

.errorMessage {
    margin-top: 10px;
    
    color: var(--color-warning);
}

@media screen and (max-width: 500px) {

    .input {
        font-size: var(--font-size-small);
    }

    
}