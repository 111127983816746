@import "../../../vars.css";

.container {
    display: flex;
    flex-direction: row;
    
    padding: 20px;

    box-shadow: var(--box-shadow);
}

.content {
    display: flex;
    align-items: start;
}

.link {
    text-decoration: none;
}

.bookInfo {
    margin-top: 7px;
    margin-left: 5px;

    text-align: left;
}

.bookTitle {
    font-size: var(--font-size-mediumSmall);
    text-align: left;
    font-family: "SanFransico";
    font-weight: 600;

    margin-bottom: 3px;

    text-decoration: none;
    color: var(--color-main)

}

.firstWordsOfTitle {
    color: var(--color-main);

    font-family: "SanFransico";
    font-weight: 600;
    text-align: left;
    font-size: var(--font-size-mediumSmall);
}

.lastWordOfTitle {
    color: var(--color-main);
    white-space: nowrap;

    font-family: "SanFransico";
    text-align: left;
    font-weight: 600;
    font-size: var(--font-size-mediumSmall);
}

.starIconFilled {
    content: url("/public/assets/images/fullFilledStar.svg");
    height: 20px;
    margin-left: 15px;
    cursor: pointer;
}


.bookSeries {
    text-align: left;

    margin-bottom: 3px;

    color: var(--color-mainLight)
}



.spacer {
    height: 12px;
}
