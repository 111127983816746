@import "../../../../vars.css";

.container {

    padding: 10px;
    
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;

    width: 300px;

    background-color: var(--color-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    cursor: pointer;
}

.containerActive {

    padding: 10px;
    
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;

    width: 300px;

    background-color: var(--color-gray);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    cursor: pointer; 
}

.content {
    display: flex;
    flex-direction: column;
}

.seriesName {
    text-align: left;
    color: var(--color-main);
    font-size: var(--font-size-small);

    margin-bottom: 5px;
}

.link {
    text-decoration: none;
}