@import "../../../vars.css";

.container {
    display: flex;
    flex-direction: row;
    
    padding: 18px;

    margin-top: 10px;

    box-shadow: var(--box-shadow);

}

.bookInfoAndNote {
    margin-top: 5px;
    margin-left: 5px;
    text-align: left;
    
}

.link {
    text-decoration: none;
}

.bookTitle {
    font-size: var(--font-size-mediumSmall);
    text-align: left;

    margin-bottom: 3px;

    text-decoration: none;
    color: var(--color-main)

}


.note {
    margin-top: 10px;

    text-align: left;
    color: var(--color-text);
    text-indent: 15px;
}

@media screen and (max-width: 500px) {

    .container {
        width: 325px;
    }

    .bookTitle {
        font-size: var(--font-size-mediumSmall);
    }
}