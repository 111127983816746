@import "../../vars.css";

.bigContainer {
    display: flex;
    flex-direction: column;

    width: 529.9px;
    min-width: 200px;
    height: 50px;

    background-color: var(--color-bg);
}

.container {
    display: flex;
    flex-direction: column;

    align-self: center;
    
    z-index: 1;

    width: fit-content;
    min-width: 200px;
}

.barAndDropdown {
    display: grid;
    grid-template-columns: 2fr 1fr;

    margin-top: 20px;

    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}


.dropdown {
    
    display: flex;
    justify-content: end;
}

@media screen and (max-width: 500px) {

    .bigContainer {
        width: 100%
    }

    

}