@import "../../../../vars.css";

.container {
    padding: 5px;
    
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;

    width: 300px;

    background-color: var(--color-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    cursor: pointer;
}

.containerActive {
    padding: 5px;
    
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;

    width: 300px;

    background-color: var(--color-gray);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    cursor: pointer; 
}

.content {
    display: flex;
    flex-direction: row;

    margin-bottom: 5px;
}

.bookInfo {
    text-align: left;

    margin-top: 10px;
}

.bookCover {
    height: 100px;

    margin-right: 10px;


    border-radius: 5px; 

}

.bookTitle {
    color: var(--color-main);
    font-size: var(--font-size-small);

    margin-bottom: 5px;
}

.bookSeries {
    color: var(--color-mainLight);
    font-size: var(--font-size-tiny);

    margin-bottom: 5px;
}

.link {
    text-decoration: none;
}
