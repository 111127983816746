@import "../../../vars.css";

.container {

    padding: 10px;
    
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;

    width: 300px;

    background-color: var(--color-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}

.content {
    display: flex;
    flex-direction: column;
}

.seriesName {
    text-align: left;
    color: var(--color-main);
    font-size: 18px;

    margin-bottom: 5px;
}
.bookTitle {
    text-align: left;
}

.booksInSeries {
    display: flex;
    flex-direction: row;
    color: var(--color-mainDark);
    margin-bottom: 3px;
}

.seriesNum {
    margin-right: 3px;
}
