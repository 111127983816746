@import "../../../vars.css";

.container {
    padding: 10px;
    
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;

    width: 300px;

    background-color: var(--color-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

}

.content {
    display: flex;
    flex-direction: row;
}

.authorInfo {
    text-align: left;
}

.authorImage {
    margin-right: 10px;
    height: 50px;
}

.authorName {
    color: var(--color-main);
    font-size: 18px;

    margin-bottom: 5px;
}

.booksByAuthor {
    color: var(--color-mainDark);
    margin-bottom: 3px;
}