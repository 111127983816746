@import "../../vars.css";

.container {
    display: grid;
    grid-template-columns: 120px 20px 1fr;
}
.dates {
    font-size: var(--font-size-small);
    color: var(--color-main);

    margin-bottom: 3px;
    margin-top: 3px;
}

.hyphen {
    font-size: var(--font-size-small);
    color: var(--color-main);

    margin-bottom: 3px;
    margin-top: 3px;
    margin-right: 10px;
}

.editedReading {
    display: grid;
    grid-template-columns: 124px 15px 124px 20px;

    color: var(--color-text);

    margin-bottom: 5px;
}

.editinghyphen {
    color: var(--color-text);
    font-size: var(--font-size-small);
    margin-right: 5px;
}

.dateInputStart {
    margin-right: 5px;
    padding: 2px;

    border: none;
    background-color: var(--color-grayLight);
    border-radius: var(--border-radius);

    color: var(--color-text);
    font-family: "SanFransico";
}

.dateInputFinish {
    margin-left: 5px;
    padding: 2px;

    border: none;
    background-color: var(--color-grayLight);
    border-radius: var(--border-radius);
    
    color: var(--color-text);
}

.dateInputStart:focus {
    outline: none;
}
.dateInputFinish:focus {
    outline: none;
}

.presentReading {
    color: var(--color-text);
    margin-left: 5px;
    width: 122.667px;
}

.deleteButton {
    background: transparent;
    border: none;

    margin-left: 5px;

}

.deleteIcon {
    content: url("/public/assets/images/deleteIcon.svg");
    height: 15px;

    cursor: pointer;
}

.deleteIcon:hover {
    content: url("/public/assets/images/deleteIconHover.svg");
    cursor: pointer;
}

.deleteIconGray {
    content: url("/public/assets/images/deleteIconGray.svg");
    height: 15px;
}