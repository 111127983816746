@import "../../vars.css";


.tagContainer {
    width: 320px;
}


.infoTitle {
    padding: 10px;
    font-size: 25px;
    text-align: left;

    color: var(--color-mainDark);
}

.titleAndButton {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}

.directTagContainer {
    display: flex;
    flex-direction: column;
}

.tagList {
    display: grid;
    grid-template-columns: 1fr 1fr;

    align-items: center;
}

.tag {
    padding: 5px;
}

.tagInput{
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: 5px;

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;

}

.tagInput:focus {
    outline: none;
}

.editButton {
    border: none;
    background-color: var(--color-medlight);
    margin-top: 5px;

    cursor: pointer;
}

.editButtonImage {
    height: 19px;
    align-self: baseline;
}

.errorMessage {
    color: var(--color-warning);
    padding: 5px;
}

@media screen and (max-width: 500px) {

    .tagInput {
        font-size: 17px;
    }

}