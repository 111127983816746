@import "../../vars.css";

.container {
    display: flex;
    flex-direction: column;
    
    background-color: var(--color-mediumlight);

    margin-top: 30px;
    margin-right: 15px;
    margin-left: 5%;

    padding: 10px;

    border-top: solid var(--color-main);
    box-shadow: var(--box-shadow);

    min-width: 330px;

}

.infoTitle {
    padding: 10px;
    padding-bottom: 5px;
    font-size: 25px;
    text-align: left;

    color: var(--color-mainDark);
}

.infoSubTitle {
    padding-left: 10px;
    padding-bottom: 5px;

    font-size: var(-font-size-small);
    color: var(--color-text);
    font-weight: 400;
    text-align: left;
}

.titleAndButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 500px) {

    .container {
        width: 325px;

        margin-left: 5%;
        margin-right: 5%;

    }
}