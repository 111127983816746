@import "../../vars.css";

.container {
    display: flex;
    flex-direction: column;

    justify-content: left;
}

.contentXS {
    font-size: var(--font-size-small);
    color: var(--color-mainDark);

    text-decoration: none;
    text-align: left;

    font-weight: 600;
}

.andXS {

}

.contentS {
    font-size: var(--font-size-mediumSmall);
    color: var(--color-mainDark);

    text-decoration: none;
    text-align: left;

    font-weight: 600;
}

.andS {
    margin-left: 5px;
    font-size: var(--font-size-mediumSmall);
    color: var(--color-mainDark);
}


.contentL {
    font-size: var(--font-size-mediumLarge);
    font-weight: 600;

    text-align: right;
    color: var(--color-mainDark);

    text-decoration: none;
}

.andL {
    font-size: var(--font-size-mediumLarge);

    margin-top: 8px;
    margin-left: 7px;
    margin-right: 7px;

    text-align: right;
    color: var(--color-mainDark);
}

