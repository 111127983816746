@import "../../vars.css";

.dropdownItem {
    padding: 5px;
    margin: 5px;

    width: 75%;

    cursor: pointer;
    border-radius: var(--border-radius);
}

.dropdownItem:hover {
   color: var(--color-main);
}

.deleteDropdownItem {
    padding: 5px;
    margin: 5px;

    width: 75%;

    cursor: pointer;
    border-radius: var(--border-radius);
    color: var(--color-warningLight);
}

.deleteDropdownItem:hover {
    color: var(--color-warning);
}