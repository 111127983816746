@import "../../vars.css";

.container {  
    padding-bottom: 5px;
}

.content {
    margin-top: 20px;

    display: grid;

    grid-template-rows: 270px;

    align-items: top;

    overflow-x: hidden;
    overflow-y: hidden;

    min-width: 650px;

}

.emptyShelf {
    margin: 25px;
    padding: 20px;
    
    height: 250px;

    font-size: 18px;
    color: var(--color-text);

    grid-column-start: 1;
    grid-column-end: span 3;

    grid-row-start: 1;
    grid-row-end:span 2;
}

.loader {
    grid-column-start: 2;

    margin-top: 50px;
    justify-content: center;
}

@media screen and (max-width: 830px) {
    .content {
       max-width: 830px;
    }
}

@media screen and (max-width: 500px) {
    .content {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 215px;

        max-width: 320px;
        min-width: 320px
    }

    .loader {
        height: 185px;
    }

}