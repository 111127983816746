@import "../../vars.css";

.container {
    background-color: transparent;

    width: 450px;
    min-width: 200px;

    margin-top: 10px;
}

.books {
    display: flex;
    flex-direction: column;

    align-items: left;

    background-color: var(--color-bg);

    width: 310px;

}

.addItemButton {
    display: flex;
    flex-direction: column;
    
    padding: 5px;

    margin-top: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;

    height: 75px;
    width: 300px;

    background-color: var(--color-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    border: none;

    color: var(--color-main);
    font-size: 18px;
    font-family: "SanFransico";
    text-align: center; 
    justify-content: center;

    cursor: pointer;
}

.addItemButton:hover {
    background-color: var(--color-grayLight);
}

.link {
    text-decoration: none;
}

@media screen and (max-width: 500px) {

    .container {
        width: 175px;
    }
}