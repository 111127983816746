@import "../../vars.css";

.container {
    height: 100px;

    margin-left: 5%;
    margin-right: 5%;
 
}

.content {
    margin-top: 50px;

    color: var(--color-mainLight);
    border-top: solid 1px var(--color-gray);
    
    text-align: center;

    font-family: "SanFransico";
}

.title {
    margin-top: 15px;
}

.body {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: baseline;
}

.link {
    text-decoration: none;
    color: var(--color-mainLight);
    cursor: pointer;

    padding: 10px;
}

.divider {
    color: var(--color-mainLight);
    padding: 10px;

    font-size: var(--font-size-medium);
}