@import '../../vars.css';

.container {
    margin-top: 25px;
    
    margin-left: 5%;
    margin-right: 5%;

    max-width: var(--max-width);

}

.content {
    display: flex;
    flex-direction: column;
}

.currentMedia {
}

@media screen and (max-width: 830px) {

    .currentMedia {
        align-items: center;
    }

}