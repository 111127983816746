@import "../../vars.css";

.contentOpen {
    position: absolute;
    display: flex;
    flex-direction: column;

    z-index: 2;

    text-align: left;

    margin-top: 7px;
    padding: 5px;
    
    font-size: 20px;
    font-weight: 500;

    background-color: var(--color-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    
    max-height: 40vh;
    width: 280px;

    color: var(--color-mainDark);

    transition: var(--speed) ease;
}

.contentClosed {
    opacity: 0;
}