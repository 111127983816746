@import "../../vars.css";

.dropdownButtonOpen {
    display: flex;
    align-items: start;
    justify-content: start;

    cursor: pointer;

    border-radius: var(--border-radius);
    border-style: none;

    font-size: var(--font-size-large);
    font-weight: 650;
    font-family: "SanFransico";

    color: var(--color-main);
    background-color: var(--color-bg);
}

.dropdownButtonClosed {
    display: flex;
    align-items: start;
    justify-content: start;

    cursor: pointer;

    border-radius: var(--border-radius);
    border-style: none;

    font-size: var(--font-size-large);
    font-weight: 650;
    font-family: "SanFransico";
    
    color: var(--color-main);
    background-color: var(--color-bg);
}

.carrot {
    height: 45px;

    display: flex;

    margin-top: 5px;
    margin-left: 15px;

}

@media screen and (max-width: 500px) {
    
    .dropdownButtonClosed {
        font-size: var(--font-size-mediumLarge);
    }

    .dropdownButtonOpen {
        font-size: var(--font-size-mediumLarge);
    }

    .carrot {
        height: 32px;
    }

}