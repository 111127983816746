@import "../../vars.css";

.container {

    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;

    vertical-align: middle;

}

.pageItiratorAndSave {
    display: flex;
    flex-direction: row;

    margin-top: 10px;
}

.inputBox {
    width: 180px;
    height: 40px;

    padding: 10px;

    margin-bottom: 5px;
    margin-right: 20px;

    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-small);
    color: var(--color-text);
    font-family: "SanFransico";

}

.inputBox:focus {
    outline: none;
}

.saveButton {
    border-radius: var(--border-radius);

    background-color: var(--color-grayLight);
    color: var(--color-text);

    text-align: center;
    font-size: var(--font-size-tiny);

    width: 80px;
    height: 40px;

    border: none;
    padding: 10px;

    cursor: pointer;
    
}

.saveButton:hover {
    background-color: var(--color-gray);
}