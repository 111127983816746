@import "../../vars.css";

.container {
    margin-right: auto;
    margin-left: auto;

    max-width: var(--max-width);
}

.sectionTitle {
    margin-left: 5%;
    margin-right: 5%;
    
    padding: 5px;
    padding-bottom: 10px;

    margin-top: 50px;

    font-size: var(--font-size-large);
    text-align: left;
    color: var(--color-main);
    font-weight: 550;

    text-wrap: balance;
}

.content {
    margin-left: 5%;
    margin-right: 5%;

    margin-bottom: 25px;

    padding: 15px;
    
    border-top: solid var(--color-main);

}

.selectedBooksContainer {
    text-align: left;
    margin-bottom: 100px;

}

.tagGridContainer {

    column-count: 3;
    gap: 15px;
    
}

/* .letterGroup {

} */

.bookGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 15px;
    
}

.emptyMessageContainer{
    margin: 25px;
    padding: 20px;
    
    height: 250px;

    font-size: var(--font-size-mediumSmall);
    color: var(--color-text);
    text-align: center;

    grid-column-start: 1;
    grid-column-end: 4;

}

.emptyMessage {
    padding: 20px;
    font-size: var(--font-size-mediumLarge);
    color: var(--color-text);


}

.emptyShelf {
    margin: 25px;
    padding: 20px;
    
    height: 250px;

    font-size: var(--font-size-mediumSmall);
    color: var(--color-text);

    column-span: all;
}

.removeTagButton {
    border: none;
    padding: 10px;

    border-radius: var(--border-radius);
    color: var(--color-text);
}

.removeTagButton:hover {
    background-color: var(--color-warning);
}

.loader {
    column-span: all;
}

.loaderForMediaTagged {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1482px) {
    .bookGridContainer {
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    .emptyShelf {
        grid-column-start: 1;
        grid-column-end: span 4;
    }
}

@media screen and (max-width: 998px) {
    .bookGridContainer {
        grid-template-columns: 1fr;
       
    }
    .tagGridContainer {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 790px) {

    .tagGridContainer {
        column-count: 2;
    }
}


@media screen and (max-width: 500px) {

    .sectionTitle {
        font-size: var(--font-size-mediumLarge);

        margin-top: 30px;
    }

    .tagGridContainer {
        column-count: 1;
        justify-content: center;
    }

    
}
