@import "../../vars.css";

.dropdownButtonOpen {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;

    margin-right: 20px;

    width: 280px;

    padding: 10px;

    background-color: var(--color-bg);
    box-shadow: var(--box-shadow);

    cursor: pointer;

    border-radius: var(--border-radius);
    border-style: none;

    font-size: 20px;
    font-weight: 600;
    font-family: "SanFransico";
    color: var(--color-main);
    text-align: left;
}

.dropdownButtonClosed {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;

    width: 280px;
    margin-right: 20px;

    padding: 10px;

    background-color:var(--color-bg);

    cursor: pointer;

    border-radius: var(--border-radius);
    border-style: none;

    font-size: 20px;
    font-weight: 600;
    font-family: "SanFransico";
    color: var(--color-main);
    text-align: left;
}

.carrot {
    height: 24px;

    justify-self: end;
    

}