@import 'vars.css';


@font-face {
  font-family: "Baskerville";
  src: local("LibreBaskerville-Regular"), url("../public/fonts/LibreBaskerville-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "SanFransico"; 
  src: local("SystemSanFranciscoDisplayRegular"), url("../public/fonts/SystemSanFranciscoDisplayRegular.ttf") format('truetype');
}

/* Default CSS*/
*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  /* font-weight: 300; */
  /* font-family: "SanFransico"; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow-x: hidden;
  position: relative;
  background-color: var(--color-bg) !important;
  color: #5F5163 !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}


ul, li {
  display: inline;
  padding: 10px;
  vertical-align: middle;
  z-index: 1;
}


.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
