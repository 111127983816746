@import "../../../vars.css";

.container {
    display: flex;
    flex-direction: column;

    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);

    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    overflow: hidden;
    justify-content: center;

    height: 245px;
}

.dateAndOutNowMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pubDate {
    color: var(--color-text);
    font-size: var(--font-size-tiny);

    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.outNow {
    color: var(--color-warningLight);
    font-size: var(--font-size-tiny);

    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.link {
    text-decoration: none;
}

@media screen and (max-width: 500px) {

    .container {
        height: 200px;
        padding: 0;
    }

}