@import "../../../vars.css";

.container {

    padding: 10px;
    padding-left: 15px;
    
    margin-bottom: 10px;

    width: 250px;

    background-color: var(--color-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    cursor: pointer;
}

.title {
    margin-bottom: 5px;
    

    color: var(--color-main);
    text-align: left;
}