@import "../../vars.css";

.container {  
    padding-bottom: 5px;

    padding-left: 10px;
}

.content {
    margin-top: 20px;
    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 270px;

    align-items: top;

    overflow-x: hidden;
    overflow-y: hidden;

    min-width: 650px;
    max-width: 650px;

 
}

.emptyShelf {
    margin: 25px;
    padding: 20px;
    
    height: 225px;

    font-size: var(--font-size-mediumSmall);
    color: var(--color-text);

    grid-column-start: 1;
    grid-column-end: span 3;

    grid-row-start: 1;
    grid-row-end:span 2;
}

@media screen and (max-width: 830px) {
    .content {
       max-width: 830px;
    }
}

@media screen and (max-width: 500px) {

    .container {
        padding-left: 0;
    }
    
    
    .content {
        grid-template-columns: 1fr 1fr;

        max-width: 320px;
        min-width: 320px;
        
        grid-template-rows: 215px;

    }

}