@import "../../vars.css";

.container {
    max-width: var(--max-width);

    margin-left: auto;
    margin-right: auto;

}

.bugForm {
    display: flex;
    flex-direction: column;

    align-items: center;

    max-width: var(--max-width);

    margin-top: 35px;

    margin-left: 5%;
    margin-right: 5%;
}

.inputLableGroup {
    display: flex;
    flex-direction: column;
}

.label {
    margin-top: 20px;
    color: var(--color-text);
    font-family: "SanFransico";

    font-weight: 600;
    font-size: var(--font-size-small);
}

.input {
    background-color: var(--color-grayLight);
    border-style: none;
    border-radius: var(--border-radius);

    padding: 10px;

    margin-left: 15px;
    margin-right: 15px;

    margin-top: 10px;

    font-family: "SanFransico";
    font-size: var(--font-size-tiny);

    width: 400px;
}

.input:focus {
    outline: none;
}

.subtitle {
    color: var(--color-text);
    text-align: center;

    margin-top: 5px;
    margin-left: 15px;
    margin-right: 15px;

    width: 400px;
}

.submitButton {
    background-color: var(--color-grayLight);
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);

    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;

    margin-bottom: 50px;

    padding: 10px;

    width: 215px;

    cursor: pointer;
}

.submitButton:hover{
    background-color: var(--color-gray);
}

.link {
    display: flex;
    flex-direction: row;

    align-items: end;
    justify-content: center;

    text-decoration: none;
    color: var(--color-text);

    font-size: var(--font-size-mediumLarge);

}

.github {
    margin-top: 50px;

}

.githubLogo {
    height: 30px;

    margin-left: 10px;
    margin-bottom: 5px;
}