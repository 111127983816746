@import "../../../vars.css";

.container {
    display: flex;
    flex-direction: row;
    
    padding: 18px;

    margin-bottom: 35px;
    margin-right: 15px;
    margin-top: 10px;

    box-shadow: var(--box-shadow);
    
    cursor: pointer;
}

.content {
    display: flex;
    align-items: start;
}

.bookCover {
    height: 200px;
    width: auto;
    align-items: center;

    margin: 10px;

    border-radius: var(--border-radius);
}

.noCover {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 10px;

    height: 200px;
    text-align: center;
    color: var(--color-text);
    background-color: var(--color-grayLight);
    border-radius: var(--border-radius);
}


.bookInfo {
    margin-top: 7px;
    margin-left: 5px;

    text-align: left;
}

.bookTitle {
    font-size: var(--font-size-mediumSmall);
    text-align: left;
    font-family: "Baskerville";
    font-weight: 600;

    margin-bottom: 3px;
    margin-left: 5px;

    text-decoration: none;
    color: var(--color-main)
}

.bookAuthor {
    font-size: var(--font-size-mediumSmall);
    margin-left: 5px;
    text-align: left;

    margin-bottom: 3px;

    color: var(--color-mainDark)
}