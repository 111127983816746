@import "../../vars.css";

.container {
    display: flex;
    flex-direction: row;
}

.pageButton {

    padding-left: 5px;
    padding-right: 5px;
    background-color: transparent;
    border: none;

    color: var(--color-main);

    font-size: var(--font-size-mediumSmall);;
    font-family: "SanFransico";

    cursor: pointer;
}

.pageButtonActive {

    padding-left: 5px;
    padding-right: 5px;
    background-color: transparent;
    border: none;

    color: var(--color-mainDark);

    font-size: var(--font-size-mediumSmall);;
    font-family: "SanFransico";

    cursor: pointer;

}

.pageButton:hover {

    color: var(--color-mainDark);
}

.carrot {
    height: 20px;
}

.carrotNextButton {
    background-color: transparent;
    border: none;
    
    margin-left: 5px;
}

.carrotBackButton {
    background-color: transparent;
    border: none;

    margin-right: 5px;
}

.mobilePage {
    color: var(--color-main);
}

@media screen and (max-width: 500px) {

    .pageButton {
        font-size: var(--font-size-small);
    }

    .pageButtonActive {
        font-size: var(--font-size-small);
    }

}

