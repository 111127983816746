@import "../../vars.css";

.container {
    margin-right: auto;
    margin-left: auto;

    max-width: var(--max-width);
    min-height: var(--min-height);
}

.rowOne {
    display: flex;
    flex-direction: row;
}

.rowTwo {
    display: flex;
    flex-direction: row;
}

.rowThree {
    display: flex;
    flex-direction: row;
}

.rowFour {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 830px) {
    .container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .rowOne {
        flex-direction: column;
        align-items: center;
    }

    .rowTwo {
        flex-direction: column;
    }
}

@media screen and (max-width: 500px) {

    .rowTwo {
        align-items: center;
    }
}