@import "../../vars.css";

.dropdownItem {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    margin: 5px;


    cursor: pointer;
    border-radius: var(--border-radius);
}

.dropdownItem:hover {
   color: var(--color-main);
}