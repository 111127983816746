@import "../../vars.css";
.container {
    display: flex;
    max-width: 320px;
}


.dropdown {
    margin-left: 5px;
}

.addToLibraryButton {
    background-color: var(--color-grayLight);
    color: var(--color-text);
    border: none;
    border-radius: var(--border-radius);

    font-size: 18px;
    font-weight: 600;
    font-family: "SanFransico";

    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;

    padding: 10px;

    width: 200px;

    cursor: pointer;
}

.addToLibraryButton:hover {
    background-color: var(--color-mainLight);

}

.message {
    margin-bottom: 10px;

    color: var(--color-warning);
    font-weight: 100;
}

.deleteButton {
    background-color: var(--color-grayLight) ;
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);
    color: var(--color-warning);
    font-family: "SanFransico";

    margin-top: 10px;
    margin-right: 15px;

    padding: 10px;

    width: 150px;

    cursor: pointer;

}

.deleteButton:hover {
    background-color: var(--color-warningLight);
    color: var(--color-grayLight);
}

.cancelButton {
    background-color: var(--color-grayLight) ;
    border: none;
    border-radius: var(--border-radius);

    font-size: var(--font-size-tiny);
    color: var(--color-text);
    font-family: "SanFransico";

    margin-top: 10px;
    margin-left: 15px;

    padding: 10px;

    width: 100px;

    cursor: pointer;

    justify-self: end;

}

.cancelButton:hover{
    background-color: var(--color-gray);
}